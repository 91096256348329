<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="data-list-list-view" class="data-list-container">
    <h1>{{ $t("planification_NonCloturer") }}</h1>    <br>
    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="missions">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
          <div v-if="user_role !== 'nokiaProject'&& user_role !== 'qhsNokia'" class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData_mission">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">Ajouter</span>
          </div>
      
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ missions.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : missions.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=60">
              <span>60</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=80">
              <span>80</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="mission_ref">{{ $t("mission_ref") }}</vs-th>
        <vs-th sort-key="mission">{{ $t("mission") }}</vs-th>
        <vs-th sort-key="journey">{{ $t("journey") }}</vs-th>
        <vs-th sort-key="evacuationPlan">{{ $t("evacuationPlan") }}</vs-th>
        <vs-th sort-key="riskZones">{{ $t("riskZones") }}</vs-th>
        <vs-th sort-key="startDate">{{ $t("jobRiskAssessment_step") }}</vs-th>
        <vs-th sort-key="validate_trajet">{{ $t("validate_trajet") }}</vs-th>
        <vs-th sort-key="mission_status">{{ $t("mission_status") }}</vs-th>
        <vs-th sort-key="created_at">{{ $t("created_at") }}</vs-th>
        <vs-th sort-key="updated_at">{{ $t("updated_at") }}</vs-th>
        <vs-th sort-key="action" v-if="user_role !== 'managerSubcontractor'">Action</vs-th>

     </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.poNumber}}</p>
          </vs-td>
          <vs-td>
           <div v-if="tr.missionStep === true" class="flex mb-2">
            <vx-tooltip color="primary" text="Voir la mission">
            <vs-button @click="missionStep_show(tr)" color="primary" size="default" icon-pack="feather" type="line" icon="icon-eye"></vs-button>    
            </vx-tooltip>

            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'" color="warning" text="Modifier la mission">
            <vs-button @click="missionStep_editData(tr)" color="warning" size="default"  type="line" class="ml-2" icon="edit"></vs-button>
            </vx-tooltip>

            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'" color="danger" text="Supprimer la mission">
            <vs-button @click="deleteData_mission(tr.id)" color="danger" size="default"  type="line" class="ml-2" icon="delete"></vs-button>
            </vx-tooltip>

            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'" text="Confimer le(s) véhicule(s)" color="success">
              <vs-button @click="missionStep_confirmation_show(tr)" color="success" type="line" class="ml-2" icon-pack="feather" icon="icon-check-circle"></vs-button>
            </vx-tooltip>
          </div>
            <div v-if="tr.missionStep === false" class="flex mb-2">  
            <vx-tooltip
            v-if="user_role !== 'nokiaProject'"
            title="Création de Mission"
            color="primary"
            text="Etape N°1">
            <vs-button @click="missionStep(tr)" color="primary" type="filled" icon-pack="feather" icon="icon-home">Ajouter</vs-button>
            </vx-tooltip>
          </div>
          </vs-td>

          <vs-td>
            <div v-if="tr.journeyStep === true" class="flex mb-2">
            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'" color="#00080a" text="Continuer le trajet" >
            <vs-button  @click="journeyStep_plus(tr)" color="#00080a" type="line"  icon-pack="feather" icon="icon-plus-circle"></vs-button>
            </vx-tooltip>

            <vx-tooltip color="primary" text="Voir le trajet">
            <vs-button  @click="journeyStep_show(tr)" color="primary" size="default" icon-pack="feather" class="ml-2" type="line" icon="icon-eye"></vs-button>    
            </vx-tooltip>

            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'" color="warning" text="Modifier le trajet">
            <vs-button  @click="journeyStep_editData(tr)" color="warning" size="default"  type="line" class="ml-2" icon="edit"></vs-button>
            </vx-tooltip>

            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'" color="danger" text="Supprimer le trajet">
            <vs-button @click="deleteData_journeys(tr)" color="danger" size="default"  type="line" class="ml-2" icon="delete"></vs-button>
            </vx-tooltip>

          </div>
            <div  v-if="tr.journeyStep === false && tr.missionStep === true" class="flex mb-2">
            <vx-tooltip
            v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'"
            title="Création de Trajet"
            color="primary"
            text="Etape N°2">
            <vs-button  @click="journeyStep(tr)" color="primary" type="filled" icon-pack="feather" icon="icon-home">Ajouter</vs-button>
            </vx-tooltip>
            </div>
          </vs-td>

          <vs-td>          
            <div v-if="tr.evacuationPlanStep === true" class="flex mb-2">
            <vx-tooltip color="primary" text="Voir le plan d'évacuation">
            <vs-button @click="evacuationPlanStep_show(tr)" color="primary" size="default" icon-pack="feather" type="line" icon="icon-eye"></vs-button>    
            </vx-tooltip>

            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'" color="warning" text="Modifier le plan d'évacuation">
            <vs-button @click="evacuationPlanStep_editData(tr)" color="warning" size="default"  type="line" class="ml-2" icon="edit"></vs-button>
            </vx-tooltip>

            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'" color="danger" text="Supprimer le plan d'évacuation">
            <vs-button @click="deleteData_evacuationPlan(tr)"  color="danger" size="default"  type="line" class="ml-2" icon="delete"></vs-button>
            </vx-tooltip>

            </div>
            <div v-if="tr.evacuationPlanStep === false && tr.journeyStep === true" class="flex mb-2">
            <vx-tooltip
            v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'"
            title="Création de Plan d'evacuation"
            color="primary"
            text="Etape N°3">
            <vs-button @click="evacuationPlanStep(tr)" color="primary" type="filled" icon-pack="feather" icon="icon-home">Ajouter</vs-button>
            </vx-tooltip>              
            </div>
          </vs-td>

          <vs-td>
            <div v-if="tr.riskZoneAssessmentStep === true" class="flex mb-2">
            <vx-tooltip  color="primary" text="Voir la zone d'exclusion">
            <vs-button @click="riskZoneAssessmentStep_show(tr)" color="primary" size="default" icon-pack="feather" type="line" icon="icon-eye"></vs-button>    
            </vx-tooltip>

            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'" color="warning" text="Modifier la zone d'exclusion">
            <vs-button @click="riskZoneAssessmentStep_editData(tr)" color="warning" size="default"  type="line" class="ml-2" icon="edit"></vs-button>
            </vx-tooltip>

            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'"  color="danger" text="Supprimer la zone d'exclusion">
            <vs-button @click="deleteData_riskZoneAssessment(tr)" color="danger" size="default"  type="line" class="ml-2" icon="delete"></vs-button>
            </vx-tooltip>

            
            </div>
            <div v-if="tr.riskZoneAssessmentStep === false && tr.evacuationPlanStep === true" class="flex mb-2">
            <vx-tooltip
            v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'"
            title="Création de la zone d'exclusion"
            color="primary"
            text="Etape N°4">
            <vs-button @click="riskZoneAssessmentStep(tr)" color="primary" type="filled" icon-pack="feather" icon="icon-home">Ajouter</vs-button>
            </vx-tooltip>              
            </div>
          </vs-td>
          
          <vs-td>
            <div v-if="tr.jobRiskAssessmentStep === true" class="flex mb-2">
            <vx-tooltip color="primary" text="Voir l'evaluation de risque">
            <vs-button  @click="jobRiskAssessmentStep_show(tr)" color="primary" size="default" icon-pack="feather" type="line" icon="icon-eye"></vs-button>    
            </vx-tooltip>

            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'" color="warning" text="Modifier l'evaluation de risque">
            <vs-button @click="jobRiskAssessmentStep_editData(tr)"  color="warning" size="default"  type="line" class="ml-2" icon="edit"></vs-button>
            </vx-tooltip>

            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'" color="danger" text="Supprimer l'evaluation de risque">
            <vs-button @click="deleteData_JobRiskAssessment(tr)" color="danger" size="default"  type="line" class="ml-2" icon="delete"></vs-button>
            </vx-tooltip>

            <vx-tooltip v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'" text="Valider l'evaluation de risque" color="success">
              <vs-button @click="jobRiskAssessmentStep_ValidateData(tr)" color="success" size="default" type="line" class="ml-2" icon-pack="feather" icon="icon-check-circle"></vs-button>
            </vx-tooltip>
          </div>
            <div v-if="tr.riskZoneAssessmentStep === true && tr.jobRiskAssessmentStep === false"  class="flex mb-2">
            <vx-tooltip
            v-if="user_role !== 'nokiaProject' && user_role !== 'qhsNokia'"
            title="Création de l'evaluation de risque"
            color="primary"
            text="Etape N°5">
            <vs-button @click="jobRiskAssessmentStep(tr)" color="primary" type="filled" icon-pack="feather" icon="icon-home">Ajouter</vs-button>
            </vx-tooltip>              
            </div>
          </vs-td>

          
          <vs-td>
          <vs-chip v-if="tr.missionJourney.length" :color="getOrderStatusColor1(tr.missionJourney[0].enclosed)" class="product-order-status">{{trajetObject[tr.missionJourney[0].enclosed]}}</vs-chip>
          </vs-td>
          <vs-td v-if="tr.missionJourney.length">
          <vs-chip v-if="tr.missionJourney[0].enclosed" :color="getOrderStatusColor(tr.enclosed)" class="product-order-status">{{validationObject[tr.enclosed]}}</vs-chip>
          <vs-chip v-if="!tr.missionJourney[0].enclosed" :color="getOrderStatusColor(2)" class="product-order-status">Non démarée</vs-chip>
          </vs-td>
        
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.created_at | date_time}}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.updated_at | date_time}}</p>
          </vs-td>
          <vs-td v-if="user_role !== 'managerSubcontractor'" class="whitespace-no-wrap">
            <div class="flex">
              <div v-if="tr.missionJourney.length">
              <vx-tooltip v-if="!tr.missionJourney[0].enclosed" :text="$t('Valider (Nokia)')" color="success">
               <feather-icon
                  style="color: darkgreen"
                  icon="CheckCircleIcon"
                  svgClasses="w-5 h-5 hover:text-success stroke-current"
                  class="mr-2"
                  @click="validerTrajet(tr)"
                />
              </vx-tooltip>
             <vx-tooltip v-if="tr.missionJourney[0].enclosed && !tr.enclosed && tr.missionJourney[0].enclosed" :text="$t('Rejeter (Nokia)')" color="danger">
                <feather-icon
                  style="color: red"
                  icon="CheckCircleIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="mr-2"
                  @click="RejeterTrajet(tr)"
                />
              </vx-tooltip>
              </div>
              <div>
              <vx-tooltip v-if=" !tr.enclosed && tr.missionJourney[0].enclosed"  :text="$t('clôturer mission')" color="success">
                <feather-icon
                  @click="validerMission(tr.id)"
                  style="color: darkgreen"
                  icon="TargetIcon"
                  svgClasses="w-5 h-5 hover:text-success stroke-current"
                  class="mr-2"
                />
              </vx-tooltip>
              <vx-tooltip v-if=" tr.enclosed && tr.missionJourney[0].enclosed"  :text="$t('Rejeter mission')" color="danger">
                <feather-icon
                  @click="RejeterMission(tr.id)"
                  style="color: red"
                  icon="TargetIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="mr-2"
                />
              </vx-tooltip>
              </div>
            </div>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
    
  </div>
</template>
<script>

export default {
  data () {
    return {
      validationObject: {
        false: 'En cours',
        true: 'Cloturée'

      },
      trajetObject: {
        false: 'Rejetée',
        true: 'Validée'

      },
      activePrompt1 : '',
      user_role : '',
      missions:[],
      isMounted: false,
      itemsPerPage: 20
    }
  },

  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.missions.length
    }
  },

  methods: {
    getOrderStatusColor (status) {
      if (status === false) {
        return 'warning'
      } else if (status === true) {
        return 'success'
      } else {
        return 'danger'

      } 
    },
    getOrderStatusColor1 (status) {
      if (status === false) {
        return 'danger'
      } else if (status === true) {
        return 'success'
      }
    },
    planification () {
      this.$router.push('/Planifications').catch(() => {})
    },
    missionStep () {
      this.activePrompt1_mission = 1
      this.$router.push('/MissionPlanifications').catch(() => {})
    },
    missionStep_show (tr) {
      this.activePrompt1_mission = 2
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionPlanifications').catch(() => {})
    },
    missionStep_editData (tr) {
      this.activePrompt1_mission = 3
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionPlanifications').catch(() => {})
    },
    missionStep_confirmation_show (tr) {
      this.activePrompt1_mission = 4
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionPlanifications').catch(() => {})
    },
    journeyStep (tr) {
      this.activePrompt1_journey = 1
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJourneys').catch(() => {})
    },
    journeyStep_show (tr) {
      this.activePrompt1_journey = 2
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJourneys').catch(() => {})
    },
    journeyStep_editData (tr) {
      this.activePrompt1_journey = 3
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJourneys').catch(() => {})
    },
    journeyStep_plus (tr) {
      this.activePrompt1_journey = 4
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJourneys').catch(() => {})
    },
    evacuationPlanStep (tr) {
      this.activePrompt1_journey = 1
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionEvacuations').catch(() => {})
    },
    evacuationPlanStep_show (tr) {
      this.activePrompt1_journey = 2
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionEvacuations').catch(() => {})
    },
    evacuationPlanStep_editData (tr) {
      this.activePrompt1_journey = 3
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionEvacuations').catch(() => {})
    },
    riskZoneAssessmentStep (tr) {
      this.activePrompt1_journey = 1
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionRiskZones').catch(() => {})
    },
    riskZoneAssessmentStep_show (tr) {
      this.activePrompt1_journey = 2
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionRiskZones').catch(() => {})
    },
    riskZoneAssessmentStep_editData (tr) {
      this.activePrompt1_journey = 3
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionRiskZones').catch(() => {})
    },
    jobRiskAssessmentStep (tr) {
      this.activePrompt1_journey = 1
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJobRiskAssessments').catch(() => {})
    },
    jobRiskAssessmentStep_show (tr) {
      this.activePrompt1_journey = 2
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJobRiskAssessments').catch(() => {})
    },
    jobRiskAssessmentStep_editData (tr) {
      this.activePrompt1_journey = 3
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJobRiskAssessments').catch(() => {})
    },
    jobRiskAssessmentStep_ValidateData (tr) {
      this.activePrompt1_journey = 4
      this.planification_mission_data = JSON.parse(JSON.stringify(tr))
      this.$router.push('/MissionJobRiskAssessments').catch(() => {})
    },
    addNewData_mission () {
      this.missionStep()
    },
 
    validerMission (mission) {
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation mission',
        text: 'Confirmer la clôture?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.post('enclose/', {mission})
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('Mission clôturée', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'échoué.' })
            })
        }
      })

    },
    RejeterMission (mission) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Rejet mission',
        text: 'Confirmer le rejet?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.post('enclose/', {mission})
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('Mission rejeté', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'échoué.' })
            })
        }
      })

    },
    validerTrajet (tr) {
      const journey = tr.missionJourney[0].id
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation trajet',
        text: 'Confirmer la validation? ',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.post('enclose/', {journey})
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('Mission validée', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'échoué.' })
            })
        }
      })

    },
    RejeterTrajet (tr) {
      const journey = tr.missionJourney[0].id
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Rejet trajet',
        text: 'Confirmer le rejet?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.post('enclose/', {journey})
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('Mission rejetée', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'échoué.' })
            })
        }
      })

    },
    deleteData_mission (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression de la mission?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`missions/${id}/`)
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('La mission est supprimée avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression a échoué.' })
            })
        }
      })

    },
    deleteData_evacuationPlan (id) {
      const id_ = id.missionEvacuationPlan[0].id
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression du plan d\'évacuation?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`evacuationPlans/${id_}/`)
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('Le plan est supprimé avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression a échoué.' })
            })
        }
      })

    },
    deleteData_journeys (id) {
      const id_ = id.missionJourney[0].id
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression du trajet?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`journeys/${id_}/`)
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('Le trajet est supprimé avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression du trajet a échoué.' })
            })
        }
      })

    },
    deleteData_riskZoneAssessment (id) {
      const id_ = id.missionRiskZoneAssessment[0].id
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression de la zone d\'exclusion',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`riskZoneAssessments/${id_}/`)
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('L\'évaluations de la zone de risque est supprimée avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression a échoué.' })
            })
        }
      })

    },
    deleteData_JobRiskAssessment (id) {
      const id_ = id.missionJobRiskAssessment[0].id
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression de l\'évaluations des risques?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`jobRiskAssessments/${id_}/`)
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success('L\' évaluations des risques d\'emploi est supprimée avec succès.', response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: 'La suppression a échoué.' })
            })
        }
      })

    },
    getAllMissions () {
      this.$vs.loading()
      this.$http.get('unclosed-missions/') 
        .then((response) => {
          this.missions = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {
    this.user_role = JSON.parse(localStorage.getItem('userInfo')).role
    window.Planifications = this
    this.planification_mission_data = {}
    this.getAllMissions()
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
<style scoped>

</style>
